/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { checkLink, setColors } from '../../../../utils/helpers';
import WrapperOne from '../../global/wrappers/WrapperOne';
import * as S from './styles';
import Facebook from '../../../images/logo/facebook.png';
import Twitter from '../../../images/logo/twitter.png';
import Linkedin from '../../../images/logo/linked-in.png';
import Insta from '../../../images/logo/insta.png';
import YouTube from '../../../images/logo/youtube.png';
import { Awards } from './Awards';

const Footer = () => {
	const data = useStaticQuery(
		graphql`
			{
				allContentfulHomePage {
					nodes {
						section11Facebook
						section11Instagram
						section11Linkedin
						section11Twitter
						section11Youtube
						section12Image {
							file {
								url
							}
							title
						}
					}
				}
				contentfulNavigationBarLinks {
					footerLinks {
						internal {
							content
						}
					}
				}
			}
		`,
	);

	const {
		section11Facebook,
		section11Instagram,
		section11Linkedin,
		section11Twitter,
		section12Image,
		section11Youtube,
	} = data.allContentfulHomePage.nodes[0];

	let footerLinks = [];
	try {
		footerLinks = JSON.parse(
			data.contentfulNavigationBarLinks.footerLinks.internal.content,
		).links;
	} catch (e) {}

	const [activeIndex, setActiveIndex] = useState(-1);

	const createLinkComponent = (link, text) => {
		const linkType = checkLink(link);
		if (linkType === 2) {
			return <Link to={`/${link}/`}>{text}</Link>;
		} else if (linkType === 1) {
			return (
				<a href={link} target="_self">
					{text}
				</a>
			);
		} else if (linkType === 0) {
			return (
				<a href={link} target="_blank" rel="noopener noreferrer">
					{text}
				</a>
			);
		}
	};
	return (
		<footer
			style={{
				backgroundColor: setColors.white,
				position: 'relative',
				zIndex: '1',
			}}
		>
			<WrapperOne disableBottomMargin="true">
				<S.Wrapper>
					<S.Content>
						<div className="logo-wrapper">
							<Awards />
						</div>
						{footerLinks.map(footerLink => (
							<div key={footerLink.section}>
								<p>{footerLink.section}</p>
								<ul>
									{footerLink.links.map(link => (
										<li key={link.text}>
											{createLinkComponent(link.destination, link.text)}
										</li>
									))}
								</ul>
							</div>
						))}
					</S.Content>
					<S.Mobile>
						<div className="padding"></div>
						{footerLinks.map((footerLink, index) => (
							<div key={footerLink.section}>
								<span
									onClick={() => {
										setActiveIndex(prev => {
											if (index === prev) return -1;
											return index;
										});
									}}
								>
									<p>{footerLink.section}</p>
									{activeIndex === index ? (
										<IoIosArrowUp />
									) : (
										<IoIosArrowDown />
									)}
								</span>
								{activeIndex === index && (
									<ul>
										{footerLink.links.map(link => (
											<li key={link.text}>
												{createLinkComponent(link.destination, link.text)}
											</li>
										))}
									</ul>
								)}
							</div>
						))}
					</S.Mobile>
				</S.Wrapper>
				<S.CompanyInformation>
					<S.CompanyContent>
						<div>
							<p>
								© {`${new Date().getFullYear()}`} WOTNOT. All rights reserved.
							</p>
						</div>
						<div>
							<span>Powered by</span>
							<a
								href="https://marutitech.com/"
								rel="noopener noreferrer "
								target="_blank"
							>
								<img
									src={section12Image.file.url}
									alt={section12Image.title}
									className="maruti-logo"
									height="32px"
									width="156px"
								/>
							</a>
						</div>
						<div>
							<p>WE ARE SOCIAL</p>
							<span>
								<a
									href={section11Facebook}
									rel="noopener noreferrer "
									target="_blank"
									title="Facebook"
								>
									<img
										src={Facebook}
										alt="icon-fb"
										border="0"
										height="47px"
										width="47px"
									/>
								</a>
								<a
									href={section11Twitter}
									rel="noopener noreferrer "
									target="_blank"
									title="Twitter"
								>
									<img
										src={Twitter}
										alt="icon-tw"
										border="0"
										height="47px"
										width="47px"
									/>
								</a>
								<a
									href={section11Linkedin}
									rel="noopener noreferrer "
									target="_blank"
									title="Linkedin"
								>
									<img
										src={Linkedin}
										alt="icon-linkedin"
										border="0"
										height="47px"
										width="47px"
									/>
								</a>
								<a
									href={section11Instagram}
									rel="noopener noreferrer "
									target="_blank"
									title="Instagram"
								>
									<img
										src={Insta}
										alt="icon-instag"
										border="0"
										height="47px"
										width="47px"
									/>
								</a>
								<a
									href={section11Youtube}
									rel="noopener noreferrer "
									target="_blank"
									title="YouTube"
								>
									<img
										src={YouTube}
										alt="icon-youtube"
										border="0"
										height="47px"
										width="47px"
									/>
								</a>
							</span>
						</div>
					</S.CompanyContent>
				</S.CompanyInformation>
			</WrapperOne>
		</footer>
	);
};

export default Footer;
