import React from 'react';
import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	SetContainerWidth,
} from '../../../../utils/helpers';
import HomepageImage from '../../../images/homepage-background.webp';

const Wrapper = styled.section`
	display: grid;
	justify-items: center;
	width: 100%;
	background-color: ${props => props.setBgColor || 'inherit'};
	${mediaQuery.mobileXL`
		margin: 0;
		margin-bottom: ${props => (props.disableBottomMargin ? '0' : '9px')};
		width: 100%;

		background: ${props => props.applyBackgroundImage && setColors.lightBlack}
	`};
	${props =>
		props.applyBackgroundImage &&
		`background-image: url(${HomepageImage});
		object-fit: cover;
    	background-size: cover;
    	background-repeat: no-repeat`}
`;

export const Content = styled.div`
	max-width: ${props => props.maxWidth || SetContainerWidth.maxWidth};
	width: ${SetContainerWidth.fullWidth};
	margin: 0 auto;
	${mediaQuery.laptopXL`width: ${props =>
		props.maxWidth === '100vw' ? '100%' : SetContainerWidth.laptopXL};`};
	${mediaQuery.tabletL`width: ${props =>
		props.maxWidth === '100vw' ? '100%' : SetContainerWidth.tabletL};`};
	${mediaQuery.mobileXL`
		width: ${props =>
			props.maxWidth === '100vw'
				? '100%'
				: SetContainerWidth.mobileXL} !important; 
		max-width: ${SetContainerWidth.fullWidth} !important;
		margin: 0 auto !important;
	`};
	${mediaQuery.mobileM`width: ${props =>
		props.maxWidth === '100vw'
			? '100%'
			: SetContainerWidth.laptopXL} !important;`};
	${mediaQuery.mobileS`width: ${props =>
		props.maxWidth === '100vw'
			? '100%'
			: SetContainerWidth.mobileS} !important;`};
	${props =>
		props.isSubHeaderText
			? `padding-bottom: 80px;
		padding-top: 40px;
		display: flex;`
			: ``}
`;

const WrapperOne = ({
	children,
	isSubHeaderText,
	wrapperClass,
	contentClass,
	disableBottomMargin,
	setBgColor,
	maxWidth,
	applyBackgroundImage,
}) => {
	return (
		<Wrapper
			className={wrapperClass}
			disableBottomMargin={disableBottomMargin}
			setBgColor={setBgColor}
			applyBackgroundImage={applyBackgroundImage}
		>
			<Content
				isSubHeaderText={isSubHeaderText}
				className={contentClass}
				maxWidth={maxWidth}
			>
				{children}
			</Content>
		</Wrapper>
	);
};

WrapperOne.defaultProps = {
	isSubHeaderText: false,
};

export default WrapperOne;
