import styled from 'styled-components';
import {
	setFontSize,
	setFontFamily,
	setFontWeight,
	mediaQuery,
	setColors,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	display: grid;
	justify-items: center;

	a {
		color: ${setColors.lightBlack};
		text-decoration: none;
	}

	padding-top: 180px;
	${mediaQuery.laptopL`padding-top: 130px;`}
	${mediaQuery.laptopM`padding-top: 100px;`}
	${mediaQuery.mobileXL`padding-top: 50px;`}
`;

export const Content = styled.div`
	ul {
		list-style: none;
		padding-left: 0rem;
	}

	p {
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.veryTiny};
		font-weight: ${setFontWeight.normal};
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 1.4px;
		text-align: left;
		margin: 0;
		padding: 10px 0;
	}

	li {
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.veryTiny};
		padding: 8px 0;
	}
	width: 100%;
	display: grid;
	grid-template-columns: 27fr 16fr 19fr 17fr 21fr;
	.logo {
		max-width: 110px;
	}
	div {
		padding-right: 15px;
	}
	${mediaQuery.laptopL`
		grid-template-columns: 29fr 17fr 20fr 17fr 17fr;
		p{
			font-size: ${setFontSize.veryVeryTiny};
		}
	`}
	${mediaQuery.laptopM`
		grid-template-columns: 24fr 26fr 24fr 26fr;
		.logo{
			padding-bottom: 39px;
		}
		.logo-wrapper{
			grid-column: 1/6;
		}
	`} 
	${mediaQuery.tabletM`
		p{
			font-size: ${setFontSize.tiniest};
		}
	`} 
	${mediaQuery.mobileXL`
		display: none;
	`}
`;

export const Mobile = styled.div`
	display: none;
	width: 100%;
	div {
		padding: 2% 0 2% 2%;
	}
	.padding {
		padding: 6% 0;
	}
	span {
		display: flex;
		align-items: center;
		justify-content: space-between;
		svg {
			width: 12px;
			height: 12px;
		}
		margin-right: -1%;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-top: 16px;
	}

	p {
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.veryTiny};
		font-weight: ${setFontWeight.normal};
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 1.4px;
		text-align: left;
		margin: 0;
		padding: 5px 0;
	}

	li {
		font-size: ${setFontSize.veryTiny};
		padding: 3px 0 3px 24px;
		line-height: 1.93;
	}
	${mediaQuery.mobileXL`
		display: block;	
	`}
	${mediaQuery.mobileS`
		.padding {
			padding: 8% 0;
		}
	`}
`;

export const CompanyInformation = styled.section`
	padding-bottom: 150px;
	margin-top: 14px;
	${mediaQuery.laptopL`padding-bottom: 100px;`}
	${mediaQuery.mobileXL`padding-bottom: 50px;margin-top: 0;`}
`;

export const CompanyContent = styled.div`
	display: grid;
	align-items: end;
	grid-template-columns: 5fr 4fr 3fr;

	div:nth-of-type(1) {
		p {
			margin: 0;
			font-size: ${setFontSize.veryTiny};
			line-height: 1.93;
		}
	}
	div:nth-of-type(2) {
		span {
			font-size: ${setFontSize.veryTiny};
			padding-right: 4%;
			display: inline-block;
			margin-bottom: 4px;
		}
	}

	.maruti-logo {
		width: 40%;
		margin-bottom: -8px;
	}

	div {
		display: grid;
		align-items: center;
	}

	div:nth-child(2) {
		display: block;
	}

	div:nth-child(3) {
		display: grid;
		justify-items: start;
		margin-left: 20%;
		margin-bottom: -6px;
		p {
			font-family: ${setFontFamily.bold};
			font-size: ${setFontSize.veryTiny};
			font-weight: ${setFontWeight.normal};
			font-style: normal;
			font-stretch: normal;
			line-height: 1.93;
			letter-spacing: 1.4px;
			text-align: left;
			margin: 0;
		}
		span {
			img {
				width: 100%;
				height: auto;
			}
			a {
				display: inline-block;
				width: 100%;
				max-width: 47px;
			}
			max-height: 47px;
			padding-top: 2%;
			margin-left: -6px;
			display: flex;
			width: 100%;
		}
	}
	${mediaQuery.laptopL`
		.maruti-logo{
			width: 46%;
		}
	`}
	${mediaQuery.laptopM`
		.maruti-logo{
			width: 50%;
		}
	`}
	@media(max-width: 1149px) {
		.maruti-logo {
			margin-bottom: -10px;
		}
		div:nth-child(3) {
			margin-bottom: -8px;
		}
	}
	${mediaQuery.mobileXL`
		grid-template-columns: 1fr;
		div:nth-of-type(3){
			order: 1;
			p{
				display: none;
			}
			margin: 0;
			display: block;
			span{
				justify-content: center;
				max-height: 40px;
				width: 40%;
				a{
					max-width: 40px;
				}
				padding: 6% 0 calc(6% - 10px) 0;
				margin: 0 auto;
			}
		}
		div:nth-of-type(2){
			order: 3;
			text-align: center;
			img{
				width: 40%;
			}
			padding: 6% 0;
		}
		div:nth-of-type(1){
			order: 2;
			padding: 6% 0;
			text-align: center;
		}
	`}
	@media(max-width: 471px) {
		div:nth-of-type(3) {
			span {
				padding: 6% 0 calc(6% - 15px) 0;
			}
		}
	}
	${mediaQuery.mobileS`
		div:nth-of-type(3) {
			span {
				width: 60%;
				max-height: 50px;
				a{
					max-width: 50px;
				}
				padding: 8% 0 calc(8% - 10px) 0;
			}
		}
		div:nth-of-type(2){
			padding: 8% 0;
		}
		div:nth-of-type(1){
			padding: 8% 0;
		}
	`}
	@media(max-width: 371px) {
		div:nth-of-type(3) {
			span {
				padding: 8% 0 calc(6.5% - 10px) 0;
			}
		}
	}
`;

export const LogoHolder = styled.span`
	display: flex;
	justify-content: flex-start;
	background-color: pink;
`;

export const AwardsWrapper = styled.div`
	${mediaQuery.laptopM`
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	`}
`;
export const AwardsRow = styled.div`
	margin-bottom: 10px;
	display: flex;
	.awardProductHunt {
		width: 250px;
		height: 54px;
		img {
			width: 100%;
		}
	}
	.awardG2 {
		width: 125px;
		img {
			width: 87px !important;
			height: 113px !important;
		}
	}

	${mediaQuery.laptopM`
		.awardProductHunt {
			width: 200px;
			height: 34px;
		}
		
		
		.awardG2 {
			width: 80px;
			img {
				width: 70px !important;
				height: 90px !important;
			}
		}
		
	`}
`;

export const Award = styled.div``;
