import { css } from 'styled-components';

export const setFontFamily = {
	bold: `'CircularStd-Bold', sans-serif`,
	black: `'CircularStd-Black', sans-serif`,
	book: `'CircularStd-Book', sans-serif`,
	medium: `'CircularStd-Medium', sans-serif`,
	jost_medium: `'Jost-Medium', 'CircularStd-Medium', sans-serif`,
};

export const flexPosition = {
	center: `display:flex;
	justify-content:center;
	align-items:center`,
};

export const setTextBoxWidth = {
	laptopXL: '80%',
	mobileS: '88%',
	tabletL: '90%',
	mobileXL: '400px',
	fullWidth: '100%',
};

export const setRichTextWidth = {
	small: '400px',
	tablet: '580px',
	desktop: '680px',
	extraLarge: '30vw',
};

export const SetWidth = {
	small: '400px',
	tablet: '90vw',
	desktop: '80vw',
	large: '1170px',
};

export const SetContainerWidth = {
	laptopXL: '80%',
	mobileS: '88%',
	tabletL: '90%',
	mobileXL: '440px',
	fullWidth: '100%',
	maxWidth: '1170px',
};

export const setColors = {
	themeBlue: '#2a7cff',
	blue: '#1A6FF6',
	lightBlue: '#DBEBFC',
	lightestBlue: '#F5F6FA',
	linkedinBlue: '#0270ad',
	twitterBlue: '#439cd6',
	fbBlue: '#1877f2',
	navyBlue: '#dbebfb',
	gray: '#f5f6fa',
	defaultGray: 'gray',
	lightGray: '#e8e8e8',
	lightestGray: '#FAFAFA',
	faintGray: '#ebf2fc',
	subtleGray: '#ccc',
	grayish: '#c3c3c3',
	lighterGray: '#e0e0e0',
	whiteGray: '#f7f9fb',
	lightBlack: '#101010',
	warmGray: '#f5f5f5',
	formBorderGray: '#e8e9ef',
	black: '#000',
	silverGray: '#535353',
	white: '#fff',
	green: '#35b580',
	darkBlueGradiant: '#0b69ff',
	lightBlueGradiant: '#307fff',
	cyanBlue: '#0d6aff',
	screenshotBlue: '#e0ecff',
	gainsBoro: '#e1e1e1',
	slateBlack: '#535353',
	scrollGray: '#889398',
	grayishGray: '#e6e6e6',
	whitishGray: '#f0f0f7',
	lighBlackOverlay: '#0003',
	divider: '#f0f1f4',
	searchGray: '#444444',
	formErrorRed: '#ee3034',
	redditOrange: '#ff4500',
	tableGray: '#f8f8f8',
	transparentGray: '#1010101a',
	alertRed: '#a94442',
	alertBgLightRed: '#f2dede',
	alertBgDarkRed: '#e7c3c3',
	blackShadow: '#00000026',
	themePurple: '#4609a8',
	secondaryBlack: '#2d2d2d',
	secondaryGray: '#777',
	videoBgGray: '#f4f7fb',
	modalOverLayColor: '#0b0b0bcc',
	goldenOrange: '#f1b000',
	planCardGrey: '#858585',
	disabledGray: '#d3d3d3',
	progressBgColor: '#d9d9d9',
	tableHeaderBg: '#E7EBEE',
	tableHeaderBorder: '#AEC1CC',
	hrTagColor: '#B1B8BB',
	authorBioName: '#2E80FA',
	textColor: '#959595',
	tocBorderColor: '#ededed',
	planCardBackground: '#f7f7f7',
	solitude: '#e7f0fd',
	bottleGreenColor: '#EAFCF2',
};

export const setPadding = {
	large: '3rem 0rem',
};

export const setFontWeight = {
	normal: '400',
	normal450: '450',
	slightlyBold: '500',
	bolder: '600',
	bold: '700',
	extraBold: '900',
};

export const setFontSize = {
	headTitle: '4rem',
	title: '3rem',
	smallTitle: '2.5rem',
	smallestTitle: '1.8rem',
	normalAverage: '1.2rem',
	normal: '1.1rem',
	normalSmall: '1rem',
	verySmallRem: '0.9rem',

	huge: '100px',
	largerLarge: '80px',
	largest: '70px',
	extraLarge: '64px',
	larg60: '60px',
	large56: '56px',
	larger: '54px',
	large50: '50px',
	mediumLarge48: '48px',
	mediumLarge: '46px',
	littleLarge: '42px',
	large45: '45px',
	tinyLarge: '44px',
	medium: '40px',
	tinyMedium: '38px',
	medium36: '36px',
	normalLarge: '35px',
	veryLarge: '34px',
	large32: '32px',
	large: '30px',

	small: '28px',
	mediumSmall: '26px',
	littleSmall: '25px',
	smaller: '24px',
	small23: '23px',
	tinySmall: '22px',
	verySmall: '20px',
	veryVerySmall: '19px',
	tiny: '18px',
	mediumTiny: '16px',
	littleTiny: '15px',
	veryTiny: '14px',
	veryVeryTiny: '13px',
	tiniest: '12px',
	minute: '11px',
	smallest: '10px',
};

const sizes = {
	extraLarge: 1600,
	large: 1400,
	desktop: 1000,
	tablet: 768,
	phone: 576,
	small: 320,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) =>
		css`
			@media (min-width: ${sizes[label] / 16}em) {
				${css(...args)};
			}
		`;
	return acc;
}, {});

const screenSizes = {
	mobileXXS: 320,
	mobileXS: 375,
	mobileS: 400,
	mobile430: 430,
	mobileM: 500,
	mobileL: 600,
	mobile700: 700,
	mobileXL: 767,

	tabletXS: 768,
	tabletS: 800,
	tabletM: 900,
	tablet932: 932,
	tablet975: 975,
	tabletML: 991,
	tabletL: 1000,
	tabletXL: 1024,

	laptopXS: 1050,
	laptopS: 1100,
	laptopSM: 1150,
	laptopM: 1200,
	laptopML: 1250,
	laptopL: 1300,
	laptop1351: 1351,
	laptopXL: 1400,

	desktopXS: 1500,
	desktopS: 1600,
	desktopM: 1700,
	desktopL: 1920,
	desktopXL: 2560,
};

export const mediaQuery = Object.keys(screenSizes).reduce((acc, label) => {
	acc[label] = (...args) =>
		css`
			@media (max-width: ${screenSizes[label]}px) {
				${css(...args)};
			}
		`;
	return acc;
}, {});

export const validateEmail = email => {
	if (email.match(/^([^\s@]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,})$/gim)) {
		return true;
	} else {
		return false;
	}
};

// 0 - external
// 1 - internal app.wotnot.io
// 2 - internal
export const checkLink = link => {
	if (link?.startsWith('http') || link?.startsWith('https')) {
		const url = new URL(link);
		if (
			url.host === 'app.wotnot.io' ||
			url.host === 'app.test.wotnot.io' ||
			url.host === 'wotnot.io'
		) {
			return 1;
		} else {
			return 0;
		}
	} else {
		return 2;
	}
};

export const splitText = (text, index, splitBy) => {
	return text.split(splitBy)[index];
};

export const getClientId = () => {
	if (window.ga) {
		const tracker = window.ga.getAll()[0];
		if (tracker) {
			const clientId = tracker.get('clientId');
			return clientId;
		}
	}
};

export const checkIfProduction = () => {
	return (
		process.env.GATSBY_ISPRODUCTION.toString().trim().toLowerCase() === 'true'
	);
};

export const colors = [
	'#E4EFFB',
	'#FFE7CE',
	'#F8F4E1',
	'#F8F0FC',
	'#E4EFFB',
	'#FFE7CE',
	'#F8F4E1',
	'#F8F0FC',
	'#E4EFFB',
	'#FFE7CE',
	'#F8F4E1',
	'#F8F0FC',
	'#E4EFFB',
	'#FFE7CE',
	'#F8F4E1',
	'#F8F0FC',
	'#E4EFFB',
	'#FFE7CE',
	'#F8F4E1',
	'#F8F0FC',
	'#E4EFFB',
	'#FFE7CE',
	'#F8F4E1',
	'#F8F0FC',
];
