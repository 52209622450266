import React from 'react';
import { node, oneOfType, arrayOf, object } from 'prop-types';
import { ThemeProvider } from 'styled-components';

import defaultTheme from '../../../../utils/theme';

const Theme = ({ theme, children }) => (
	<ThemeProvider theme={theme}>
		<div>{children}</div>
	</ThemeProvider>
);

Theme.propTypes = {
	theme: object,
	children: oneOfType([arrayOf(node), node]),
};

Theme.defaultProps = {
	theme: defaultTheme,
};

export default Theme;
