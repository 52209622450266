import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { FaBars } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import OpenNavBar from '../mobileNav/openNavBar';
import Logo from '../../../svg/wotnot-logo.inline.svg';
import { checkLink, splitText } from '../../../../utils/helpers';
import * as S from './styles';
import { CONSTANTS, DIMENSIONS } from '../../../../utils/constants';

const Navbar = ({ top, mobileMenuIsOpen, setMobileMenuIsOpen }) => {
	const data = useStaticQuery(graphql`
		query {
			allContentfulNavigationBarLinks {
				nodes {
					dropdown1Title
					subSection1title
					subSection1content {
						title
						description
						file {
							url
						}
					}
					subSection {
						section2Title
						section2 {
							text
							subText
							redirectTo
						}
						section3Title
						section3 {
							text
							redirectTo
						}
						section4Title
						section4 {
							text
							redirectTo
						}
					}
					dropdwon2title
					dropdown2Subtitle
					dropdwon2Content {
						file {
							url
						}
						title
						description
					}
					otherLinks {
						contentful_id
						linkText
						linkUrl
					}
					dropdown3SubmenuLeftTitle
					dropdown3SubmenuRightContent {
						description
						title
						fluid(maxHeight: 130, maxWidth: 130) {
							...GatsbyContentfulFluid
						}
					}
					dropdown3SubmenuRightTitle
					dropdown3Title
					dropdown3SubmenuLeftContent {
						title
						description
						file {
							url
						}
					}
					otherLinks2 {
						contentful_id
						linkText
						linkUrl
					}
					rightCta {
						text
						destination
					}
				}
			}
		}
	`);

	const toggleHandler = x => {
		setMobileMenuIsOpen(!x);
	};

	const generateLink = (url, text, styled = false, key = '') => {
		const urlType = checkLink(url);
		if (urlType === 2) {
			return !styled ? (
				<Link to={`/${url}/`}>{text}</Link>
			) : (
				<S.GatsbyLink to={`${url}/`} key={key}>
					{text}
				</S.GatsbyLink>
			);
		} else if (urlType === 1) {
			return !styled ? (
				<a href={url} target="_self">
					{text}
				</a>
			) : (
				<S.GatsbyLink as="a" href={url} target="_self" key={key}>
					{text}
				</S.GatsbyLink>
			);
		} else {
			return !styled ? (
				<a href={url} target="_blank" rel="noopener noreferrer">
					{text}
				</a>
			) : (
				<S.GatsbyLink as="a" href={url} target="_blank" key={key}>
					{text}
				</S.GatsbyLink>
			);
		}
	};

	const {
		section2Title,
		section2,
		section3Title,
		section3,
		section4Title,
		section4,
	} = data.allContentfulNavigationBarLinks.nodes[0].subSection[0];
	const {
		dropdown1Title,
		subSection1title,
		subSection1content,
		dropdwon2title,
		dropdown2Subtitle,
		dropdwon2Content,
		otherLinks,
		dropdown3SubmenuLeftTitle,
		dropdown3SubmenuRightContent,
		dropdown3SubmenuRightTitle,
		dropdown3Title,
		dropdown3SubmenuLeftContent,
		otherLinks2,
		rightCta,
	} = data.allContentfulNavigationBarLinks.nodes[0];

	return (
		<S.NavWrapper top={top} height={DIMENSIONS.HEADER_HEIGHT}>
			<S.Nav>
				<Link to="/" className="navLogo">
					<Logo />
				</Link>

				<S.LinkHolder>
					<S.FullDropdown>
						<ul className="menu">
							<li>
								<p>
									{dropdown1Title} <IoIosArrowDown />
								</p>
								<S.StyledSubMenu
									className="full-width-menu"
									headerHeight={DIMENSIONS.HEADER_HEIGHT}
								>
									<S.StyledSubDropdown>
										<S.DropdownSectionWrapper>
											<S.StyledDropdownSectionHeader>
												{subSection1title}
											</S.StyledDropdownSectionHeader>
											<S.StyledSubContent>
												{subSection1content.map(useCases => (
													<S.NavLink
														width={'auto'}
														to={`${splitText(useCases.title, 1, '~')}`}
													>
														<div className="child-items" id="auto-shrink">
															<img
																src={useCases.file.url}
																alt={useCases.title}
															/>
															<S.StyledTextWrapper>
																<S.StyledLabel>
																	{splitText(useCases.title, 0, '~')}
																</S.StyledLabel>
																<S.StyledSubText>
																	{useCases.description}
																</S.StyledSubText>
															</S.StyledTextWrapper>
														</div>
													</S.NavLink>
												))}
											</S.StyledSubContent>
										</S.DropdownSectionWrapper>
										{section2Title && (
											<S.DropdownSectionWrapper>
												<S.StyledDropdownSectionHeader>
													{section2Title}
												</S.StyledDropdownSectionHeader>
												<S.StyledSubContent>
													{section2.map(company => (
														<S.NavLink width={'auto'} to={company.redirectTo}>
															<div className="child-items" id="auto-shrink">
																<S.StyledTextWrapper>
																	<S.StyledLabel>{company.text}</S.StyledLabel>
																	<S.StyledSubText>
																		{company.subText}
																	</S.StyledSubText>
																</S.StyledTextWrapper>
															</div>
														</S.NavLink>
													))}
												</S.StyledSubContent>
											</S.DropdownSectionWrapper>
										)}
										<S.DropdownSectionWrapper>
											<S.StyledDropdownSectionHeader>
												{section3Title}
											</S.StyledDropdownSectionHeader>
											<S.StyledSubContent>
												{section3.map(industry => (
													<S.NavLink
														width={'auto'}
														to={industry.redirectTo}
														maxWidth={'200px'}
													>
														<div className="child-link-items">
															<S.StyledTextWrapper>
																<S.StyledLabel isLight>
																	{industry.text}
																</S.StyledLabel>
															</S.StyledTextWrapper>
														</div>
													</S.NavLink>
												))}
											</S.StyledSubContent>
										</S.DropdownSectionWrapper>
										<S.DropdownSectionWrapper>
											<S.StyledDropdownSectionHeader>
												{section4Title}
											</S.StyledDropdownSectionHeader>
											<S.StyledSubContent>
												{section4.map(comparisions => (
													<S.NavLink
														width={'auto'}
														to={comparisions.redirectTo}
														maxWidth={'200px'}
													>
														<div className="child-link-items">
															<S.StyledTextWrapper>
																<S.StyledLabel isLight>
																	{comparisions.text}
																</S.StyledLabel>
															</S.StyledTextWrapper>
														</div>
													</S.NavLink>
												))}
											</S.StyledSubContent>
										</S.DropdownSectionWrapper>
										{/* </div> */}
									</S.StyledSubDropdown>
								</S.StyledSubMenu>
							</li>
						</ul>
					</S.FullDropdown>
					<S.FullDropdown>
						<ul className="menu">
							<li>
								<p>
									{dropdwon2title} <IoIosArrowDown />
								</p>
								<S.StyledSubMenu
									className="full-width-menu"
									headerHeight={DIMENSIONS.HEADER_HEIGHT}
								>
									<div className="items">
										<S.FeaturesContentWrapper>
											<S.StyledDropdownSectionHeader isLeftTitle>
												{dropdown2Subtitle}
											</S.StyledDropdownSectionHeader>
											<S.FeaturesWrapper>
												{dropdwon2Content.map(features => (
													<S.NavLink
														width={'auto'}
														to={splitText(features.title, 1, '~')}
													>
														<div
															className="child-items"
															style={{ width: 'auto' }}
														>
															<img
																src={features.file.url}
																alt={features.title}
															/>
															<S.StyledTextWrapper>
																<S.StyledLabel>
																	{splitText(features.title, 0, '~')}
																</S.StyledLabel>
																{features.description.length ? (
																	<S.StyledSubText>
																		{features.description}
																	</S.StyledSubText>
																) : null}
															</S.StyledTextWrapper>
														</div>
													</S.NavLink>
												))}
											</S.FeaturesWrapper>
										</S.FeaturesContentWrapper>
									</div>
								</S.StyledSubMenu>
							</li>
						</ul>
					</S.FullDropdown>
					{otherLinks.map(link =>
						generateLink(link.linkUrl, link.linkText, true, link.contentful_id),
					)}
					<S.FullDropdown>
						<ul className="menu">
							<li>
								<p>
									{dropdown3Title} <IoIosArrowDown />
								</p>
								<S.StyledSubMenu
									className="full-width-menu"
									headerHeight={DIMENSIONS.HEADER_HEIGHT}
								>
									<S.StyledBackground />
									<div className="items">
										<S.LeftSectionWrapper>
											<S.StyledDropdownSectionHeader isLeftTitle>
												{dropdown3SubmenuLeftTitle}
											</S.StyledDropdownSectionHeader>
											<S.LeftResourceWrapper>
												{dropdown3SubmenuLeftContent.map(resources => (
													<S.NavLink
														width={'auto'}
														to={`${splitText(resources.title, 1, '~')}`}
													>
														<div className="child-items">
															<img
																src={resources.file.url}
																alt={resources.title}
															/>
															<S.StyledTextWrapper>
																<S.StyledLabel>
																	{splitText(resources.title, 0, '~')}
																</S.StyledLabel>
																<S.StyledSubText>
																	{resources.description}
																</S.StyledSubText>
															</S.StyledTextWrapper>
														</div>
													</S.NavLink>
												))}
											</S.LeftResourceWrapper>
										</S.LeftSectionWrapper>
										<S.RightSectionWrapper>
											<S.StyledDropdownSectionHeader>
												{dropdown3SubmenuRightTitle}
											</S.StyledDropdownSectionHeader>
											<S.FeaturedResourceWrapper>
												{dropdown3SubmenuRightContent.map(blog => (
													<S.NavLink
														to={`/${splitText(blog.description, 0, '~')}/`}
													>
														<S.BlogsContainer>
															<S.BlogImgWrapper>
																<Img
																	fluid={blog.fluid}
																	alt={blog.title}
																	imgStyle={{
																		objectFit: 'fill',
																		maxHeight: '130px',
																		borderRadius: '10px',
																	}}
																/>
															</S.BlogImgWrapper>
															<S.BlogInfo>
																<div>{splitText(blog.description, 1, '~')}</div>
																<div>{blog.title}</div>
																<div>{CONSTANTS.READ_MORE}</div>
															</S.BlogInfo>
														</S.BlogsContainer>
													</S.NavLink>
												))}
											</S.FeaturedResourceWrapper>
										</S.RightSectionWrapper>
									</div>
								</S.StyledSubMenu>
							</li>
						</ul>
					</S.FullDropdown>
					{otherLinks2.map(link =>
						generateLink(link.linkUrl, link.linkText, true, link.contentful_id),
					)}
				</S.LinkHolder>
				<S.CTA>
					{generateLink(rightCta[0]['destination'], rightCta[0]['text'])}
					{generateLink(rightCta[1]['destination'], rightCta[1]['text'])}
				</S.CTA>
				<S.MobileNavigation>
					<FaBars
						className="burgerIcon"
						onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
					/>
					<OpenNavBar toggle={mobileMenuIsOpen} toggleHandler={toggleHandler} />
				</S.MobileNavigation>
			</S.Nav>
		</S.NavWrapper>
	);
};
export default Navbar;
