/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import * as S from '../navbar/styles';
import Logo from '../../../svg/wotnot-logo.inline.svg';
import { checkLink, splitText } from '../../../../utils/helpers';
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from 'react-icons/io';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { CONSTANTS } from '../../../../utils/constants';

const one = 1;

const generateCTALink = (url, text, blue) => {
	const urlType = checkLink(url);
	if (urlType === 2) {
		return (
			<S.MobileCTAButton to={`/${url}/`} blue={blue}>
				{text}
			</S.MobileCTAButton>
		);
	} else if (urlType === 1) {
		return (
			<S.MobileCTAButton href={url} as="a" target="_self" blue={blue}>
				{text}
			</S.MobileCTAButton>
		);
	} else {
		return (
			<S.MobileCTAButton
				href={url}
				as="a"
				target="_blank"
				rel="noopener noreferrer"
				blue={blue}
			>
				{text}
			</S.MobileCTAButton>
		);
	}
};

const generateLink = (url, text) => {
	const urlType = checkLink(url);
	if (urlType === 2) {
		return <Link to={`${url}/`}>{text}</Link>;
	} else if (urlType === 1) {
		return (
			<Link href={url} as="a" target="_self">
				{text}
			</Link>
		);
	} else {
		return (
			<Link href={url} as="a" target="_blank" rel="noopener noreferrer">
				{text}
			</Link>
		);
	}
};

const OpenNavBar = ({ toggle, toggleHandler }) => {
	const data = useStaticQuery(graphql`
		query {
			allContentfulNavigationBarLinks {
				nodes {
					dropdown1Title
					subSection1title
					subSection1content {
						title
						description
						file {
							url
						}
					}
					subSection {
						section2Title
						section2 {
							text
							subText
							redirectTo
						}
						section3Title
						section3 {
							text
							redirectTo
						}
						section4Title
						section4 {
							text
							redirectTo
						}
					}
					dropdwon2title
					dropdown2Subtitle
					dropdwon2Content {
						file {
							url
						}
						title
						description
					}
					otherLinks {
						contentful_id
						linkText
						linkUrl
					}
					dropdown3SubmenuLeftTitle
					dropdown3SubmenuRightContent {
						description
						title
						fluid(maxHeight: 130, maxWidth: 130) {
							...GatsbyContentfulFluid
						}
					}
					dropdown3SubmenuRightTitle
					dropdown3Title
					dropdown3SubmenuLeftContent {
						title
						description
						file {
							url
						}
					}
					otherLinks2 {
						contentful_id
						linkText
						linkUrl
					}
					rightCta {
						text
						destination
					}
				}
			}
		}
	`);

	const {
		section2Title,
		section2,
		section3Title,
		section3,
		section4Title,
		section4,
	} = data.allContentfulNavigationBarLinks.nodes[0].subSection[0];
	const {
		dropdown1Title,
		subSection1title,
		subSection1content,
		dropdwon2title,
		dropdown2Subtitle,
		dropdwon2Content,
		otherLinks,
		dropdown3SubmenuLeftTitle,
		dropdown3SubmenuRightContent,
		dropdown3SubmenuRightTitle,
		dropdown3Title,
		dropdown3SubmenuLeftContent,
		otherLinks2,
		rightCta,
	} = data.allContentfulNavigationBarLinks.nodes[0];

	const { x } = useSpring({
		x: toggle ? 0 : 100,
	});

	const [dropdownOne, setDropdownOne] = useState(false);
	const [dropdownThree, setDropdownThree] = useState(false);
	const [dropdown2, setDropdown2] = useState(false);
	return (
		<animated.nav
			style={{
				transform: x.interpolate(x => `translate3d(0, ${x * -1}%,0)`),
				position: 'fixed',
				backgroundColor: 'white',
				color: 'black',
				top: '0',
				bottom: '0',
				zIndex: one,
				left: '0',
				right: '0',
				overflow: 'scroll',
			}}
		>
			<S.Bar>
				<Link to="/" className="logo">
					<Logo />
				</Link>
				<span>
					<IoMdClose onClick={() => toggleHandler(toggle)} />
				</span>
			</S.Bar>
			<S.NavContent>
				<span onClick={() => setDropdownOne(!dropdownOne)}>
					{dropdown1Title}
					{(!dropdownOne && <IoIosArrowDown />) ||
						(dropdownOne && <IoIosArrowUp />)}
				</span>
				{dropdownOne && (
					<>
						<S.DropdownSectionWrapper>
							<S.StyledDropdownSectionHeader>
								{subSection1title}
							</S.StyledDropdownSectionHeader>
							<S.StyledSubContent>
								{subSection1content.map(useCases => (
									<S.FullDropdown>
										<S.NavLink
											width={'auto'}
											to={`${splitText(useCases.title, 1, '~')}`}
										>
											<div className="child-items">
												<img src={useCases.file.url} alt={useCases.title} />
												<S.StyledTextWrapper>
													<S.StyledLabel>
														{splitText(useCases.title, 0, '~')}
													</S.StyledLabel>
													<S.StyledSubText>
														{useCases.description}
													</S.StyledSubText>
												</S.StyledTextWrapper>
											</div>
										</S.NavLink>
									</S.FullDropdown>
								))}
							</S.StyledSubContent>
						</S.DropdownSectionWrapper>
						{section2Title && (
							<S.DropdownSectionWrapper>
								<S.StyledDropdownSectionHeader>
									{section2Title}
								</S.StyledDropdownSectionHeader>
								<S.StyledSubContent>
									{section2.map(company => (
										<S.FullDropdown>
											<S.NavLink width={'auto'} to={company.redirectTo}>
												<div className="child-items">
													<S.StyledTextWrapper>
														<S.StyledLabel>{company.text}</S.StyledLabel>
														<S.StyledSubText>{company.subText}</S.StyledSubText>
													</S.StyledTextWrapper>
												</div>
											</S.NavLink>
										</S.FullDropdown>
									))}
								</S.StyledSubContent>
							</S.DropdownSectionWrapper>
						)}
						<S.DropdownSectionWrapper>
							<S.StyledDropdownSectionHeader>
								{section3Title}
							</S.StyledDropdownSectionHeader>
							<S.StyledSubContent>
								{section3.map(industry => (
									<S.FullDropdown>
										<S.NavLink width={'auto'} to={industry.redirectTo}>
											<div className="child-link-items">
												<S.StyledTextWrapper>
													<S.StyledLabel isLight>{industry.text}</S.StyledLabel>
												</S.StyledTextWrapper>
											</div>
										</S.NavLink>
									</S.FullDropdown>
								))}
							</S.StyledSubContent>
						</S.DropdownSectionWrapper>
						<S.DropdownSectionWrapper>
							<S.StyledDropdownSectionHeader>
								{section4Title}
							</S.StyledDropdownSectionHeader>
							<S.StyledSubContent>
								{section4.map(comparisions => (
									<S.FullDropdown>
										<S.NavLink width={'auto'} to={comparisions.redirectTo}>
											<div className="child-link-items">
												<S.StyledTextWrapper>
													<S.StyledLabel isLight>
														{comparisions.text}
													</S.StyledLabel>
												</S.StyledTextWrapper>
											</div>
										</S.NavLink>
									</S.FullDropdown>
								))}
							</S.StyledSubContent>
						</S.DropdownSectionWrapper>
					</>
				)}
				<span onClick={() => setDropdown2(!dropdown2)}>
					{dropdwon2title}
					{(!dropdown2 && <IoIosArrowDown />) ||
						(dropdown2 && <IoIosArrowUp />)}
				</span>
				{dropdown2 && (
					<S.FeaturesContentWrapper>
						<S.StyledDropdownSectionHeader isLeftTitle>
							{dropdown2Subtitle}
						</S.StyledDropdownSectionHeader>
						<S.FeaturesWrapper>
							{dropdwon2Content.map(features => (
								<S.FullDropdown>
									<S.NavLink
										width={'auto'}
										to={splitText(features.title, 1, '~')}
									>
										<div className="child-items">
											<img src={features.file.url} alt={features.title} />
											<S.StyledTextWrapper>
												<S.StyledLabel>
													{splitText(features.title, 0, '~')}
												</S.StyledLabel>
												{features.description.length ? (
													<S.StyledSubText>
														{features.description}
													</S.StyledSubText>
												) : null}
											</S.StyledTextWrapper>
										</div>
									</S.NavLink>
								</S.FullDropdown>
							))}
						</S.FeaturesWrapper>
					</S.FeaturesContentWrapper>
				)}
				{otherLinks.map(link => (
					<span key={link.contentful_id}>
						{generateLink(link.linkUrl, link.linkText)}
					</span>
				))}
				<span onClick={() => setDropdownThree(!dropdownThree)}>
					{dropdown3Title}
					{(!dropdownThree && <IoIosArrowDown />) ||
						(dropdownThree && <IoIosArrowUp />)}
				</span>
				{dropdownThree && (
					<>
						<S.LeftSectionWrapper>
							<S.StyledDropdownSectionHeader isLeftTitle>
								{dropdown3SubmenuLeftTitle}
							</S.StyledDropdownSectionHeader>
							<S.LeftResourceWrapper>
								{dropdown3SubmenuLeftContent.map(resources => (
									<S.FullDropdown>
										<S.NavLink
											width={'auto'}
											to={`${splitText(resources.title, 1, '~')}`}
										>
											<div className="child-items">
												<img src={resources.file.url} alt={resources.title} />

												<S.StyledTextWrapper>
													<S.StyledLabel>
														{splitText(resources.title, 0, '~')}
													</S.StyledLabel>
													<S.StyledSubText>
														{resources.description}
													</S.StyledSubText>
												</S.StyledTextWrapper>
											</div>
										</S.NavLink>
									</S.FullDropdown>
								))}
							</S.LeftResourceWrapper>
						</S.LeftSectionWrapper>
						<S.RightSectionWrapper>
							<S.StyledDropdownSectionHeader>
								{dropdown3SubmenuRightTitle}
							</S.StyledDropdownSectionHeader>
							<S.FeaturedResourceWrapper>
								{dropdown3SubmenuRightContent.map(blog => (
									<S.NavLink to={`/${splitText(blog.description, 0, '~')}/`}>
										<S.BlogsContainer>
											<S.BlogImgWrapper>
												<Img
													fluid={blog.fluid}
													alt={blog.title}
													imgStyle={{
														objectFit: 'fill',
														maxHeight: '130px',
														borderRadius: '10px',
													}}
												/>
											</S.BlogImgWrapper>
											<S.BlogInfo>
												<div>{splitText(blog.description, 1, '~')}</div>
												<div>{blog.title}</div>
												<div>{CONSTANTS.READ_MORE}</div>
											</S.BlogInfo>
										</S.BlogsContainer>
									</S.NavLink>
								))}
							</S.FeaturedResourceWrapper>
						</S.RightSectionWrapper>
					</>
				)}
				{otherLinks2.map(link => (
					<span key={link.contentful_id}>
						{generateLink(link.linkUrl, link.linkText)}
					</span>
				))}
			</S.NavContent>{' '}
			<S.BottomButtons>
				{generateCTALink(
					rightCta[0]['destination'],
					rightCta[0]['text'],
					false,
				)}
				{generateCTALink(rightCta[1]['destination'], rightCta[1]['text'], true)}
			</S.BottomButtons>
		</animated.nav>
	);
};

export default OpenNavBar;
