import React, { useEffect } from 'react';
import { Award, AwardsRow, AwardsWrapper } from './styles';

export const Awards = () => {
	useEffect(() => {
		(function (a, b, c, d) {
			window
				.fetch('https://www.g2.com/products/wotnot/rating_schema.json')
				.then(e => e.json())
				.then(f => {
					var el = window.document.getElementById('g2_awards_schema');
					var c_el = window.document.getElementById('contentful_json_schema');
					if (!el && !c_el) {
						c = a.createElement(b);
						c.id = 'g2_awards_schema';
						c.type = 'application/ld+json';
						c.text = JSON.stringify(f);
						d = a.getElementsByTagName(b)[0];
						d.parentNode.insertBefore(c, d);
					} else if (el && c_el) {
						el.remove();
					}
				})
				.catch(() => {});
		})(document, 'script');
		(function () {
			var sc = document.createElement('script');
			sc.type = 'text/javascript';
			sc.async = true;
			sc.src = 'https://b.sf-syn.com/badge_js?sf_id=3339125&variant_id=sf';
			var p = document.getElementsByTagName('script')[0];
			p.parentNode.insertBefore(sc, p);
		})();
	}, []);
	return (
		<AwardsWrapper>
			<AwardsRow>
				<Award className="awardG2">
					<a
						title="WotNot is a leader in Chatbots on G2"
						href="https://www.g2.com/products/wotnot/reviews?utm_source=rewards-badge"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							width="87"
							height="113"
							alt="G2 badge Chatbot High Performer"
							src="https://images.ctfassets.net/foc8yxpzaiuk/35K7TZbZ7wgiStPzchZc7a/4631d2391400e51f6b2e4ccf543a4c6b/Chatbots_HighPerformer_HighPerformer.svg"
						/>
					</a>
				</Award>
				<Award className="awardG2">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.g2.com/products/wotnot/reviews?utm_source=rewards-badge"
					>
						<img
							src="https://images.ctfassets.net/foc8yxpzaiuk/2pAdiSynZpv0hHIsYv5THX/38ed12ec347e066c18389e03c89306d6/Chatbots_HighPerformer_Small-Business_HighPerformer.svg"
							alt="G2 badge Chatbot High Performer Small Business"
							width="87"
							height="113"
						/>
					</a>
				</Award>
			</AwardsRow>
			<AwardsRow>
				<Award>
					<img
						width="100"
						height="100"
						alt="AICPA SOC Compliance"
						src="https://images.ctfassets.net/foc8yxpzaiuk/3KYjvSO4MBdLNB8HJxW60A/4e04db12942c87ce65d684f44142f321/AICPA-SOC.jpeg"
					/>
				</Award>
				<Award>
					<img
						width="125"
						height="125"
						src="https://images.ctfassets.net/foc8yxpzaiuk/5m7zDWWwYp8MFWn1Nz471F/871dd963898a9113040498bdbcf6a9a7/GDPR-AWARD.png"
						alt="GDPR Compliance"
					/>
				</Award>
			</AwardsRow>
		</AwardsWrapper>
	);
};
