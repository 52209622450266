import styled from 'styled-components';
import { Link } from 'gatsby';
import {
	mediaQuery,
	setFontSize,
	flexPosition,
	setFontFamily,
	setColors,
	setFontWeight,
} from '../../../../utils/helpers';
import '../../../fonts/typography.css';

export const NavWrapper = styled.div`
	background-color: white;
	z-index: 4;
	height: ${({ height }) => height || '66px'};
	box-sizing: border-box;
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	padding: 0 2%;
	max-width: 100vw;
	margin: 0 !important;
	line-height: 66px;
	box-shadow: ${props =>
		props.top ? 'none' : '0 7px 10px 0 rgb(236 236 236 / 30%)'};
	${mediaQuery.tabletXS`
		padding: 0 3%;
	`}
	${mediaQuery.mobileXL`
		height: auto;
	`}
`;

export const Nav = styled.nav`
	display: flex;
	position: -webkit-sticky; /* Safari */
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: relative;
	.navLogo {
		width: 190px;
		float: left;
		position: absolute;
		left: 0;
		padding-top: 3px;
		top: 10%;
		overflow: hidden;
		height: 100%;
		box-sizing: border-box;
		svg {
			width: 150px;
		}
	}

	${mediaQuery.tabletL`
		.navLogo{
			svg{
				width: 140px;
			}
		}
	`}
	@media (max-width: 850px) {
		.navLogo {
			svg {
				width: 110px;
			}
		}
	}
	${mediaQuery.tabletXS`
		.navLogo{
			padding: 0;
			svg{
				width: 105px;
			}
		}
	`}
	${mediaQuery.mobileXL`
		position: static;
		.navLogo{
			position: relative;
			width: auto;
			height: 80px;
			display: flex;
			align-items: center;
			margin-left: 9px;
			svg{
				width: 130px;
				padding-top: 4px;
			}
		}
	`}
`;

export const CTA = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	a {
		padding: 12px 25px;
		${flexPosition.center};
		border-radius: 4px;
		font-size: ${setFontSize.mediumTiny};
		text-decoration: none;
		line-height: 16px;
		box-sizing: border-box;
		top: 13px;
	}

	a:nth-child(1) {
		color: ${setColors.themeBlue};
		position: fixed;
		right: 180px;
		padding: 10px 20px;
		border: 2px solid transparent;
		transition: 0.15s;
		&:hover {
			border: 2px solid ${setColors.themeBlue};
		}
	}

	a:nth-child(2) {
		color: white;
		background-color: transparent;
		background-image: linear-gradient(
			to bottom,
			${setColors.darkBlueGradiant},
			${setColors.lightBlueGradiant}
		);
		box-shadow: 0 6px 15px 0 rgba(31, 117, 255, 0.2);
		position: fixed;
		right: 20px;
	}

	${mediaQuery.laptopXL`
		a{
			font-size: ${setFontSize.veryTiny};
			line-height: 1;
			top: 14px;
		}
	`};

	@media only screen and (min-width: 1001px) and (max-width: 1080px) {
		a:nth-child(1) {
			right: 170px;
			padding: 10px 15px;
		}
	}
	${mediaQuery.tabletL`
		a{
			font-size: ${setFontSize.tiniest};
			top: 16px;
		}
		a:nth-child(1){
			right: 160px;
			padding: 10px;
		}
	`}
	@media (max-width: 850px) {
		a {
			padding: 10px;
		}
		a:nth-child(1) {
			right: 130px;
		}
		a:nth-child(2) {
			top: 18px;
		}
	}
	${mediaQuery.mobileXL`
		display:none;
	`}
`;

export const LinkHolder = styled.div`
	width: 796px;
	left: 0;
	margin: 0 auto;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	p {
		font-family: ${setFontFamily.bold};
		font-weight: ${setFontWeight.normal};
		margin: 0;
		display: flex;
		align-items: center;
		font-size: ${setFontSize.mediumTiny};
		line-height: 1;
	}

	${mediaQuery.laptopXL`
		width: 630px;
		p{
			font-size: ${setFontSize.veryTiny};
		}
	`}
	${mediaQuery.tabletL`
		width: 530px;
	`}
	@media (max-width: 850px) {
		width: 522px;
	}
	${mediaQuery.tabletXS`
		width: 440px;
		margin-left: 110px;
	`}
	${mediaQuery.mobileXL`
		display: none;
	`}
`;

export const DropdownTwo = styled.div`
	margin: 0 20px;

	// Menu styles
	ul:nth-of-type(1) {
		background-color: lighten(gray, 15%);
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;

		// Menu link styles

		// Top-level menu items
		> li {
			display: block;
			position: relative;
			p {
				line-height: 66px;
			}
		}
	}

	div {
		padding: 6px 8px 10px 8px;
		${mediaQuery.tabletL`
			padding: 4px 8px 12px 8px
		`}
	}

	.sub-menu {
		//clip: rect(1px, 1px, 1px, 1px);
		opacity: 0.1;
		transition: all 0.3s;
		//transform: translateY(-10px) scaleY(0.5); transform 0.1s cubic-bezier(0.2, 0, 0.2, 1),
		transform-origin: top;
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
		// Typical sub-menu styling
		box-shadow: 5px 5px 5px rgba(black, 0.3);
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 100%;
		left: 0;
		width: 220px;
		margin-top: 2px !important;
		visibility: hidden;
		div {
			li {
				min-height: 35px;
				border-radius: 7px;
				overflow: hidden;
				.features {
					font-weight: ${setFontWeight.bold} !important;
				}
				a,
				p {
					font-size: ${setFontSize.mediumTiny};
					${mediaQuery.laptopXL`
						font-size: ${setFontSize.veryTiny};	
					`}
					${mediaQuery.tabletL`
						font-size: ${setFontSize.tiniest};
					`}
				}
				a {
					line-height: 23px;
					padding: 8px 11px;
					box-sizing: border-box;
					min-height: 35px;
				}
				p {
					line-height: 23px;
					padding: 8px 11px;
					box-sizing: border-box;
				}
				${mediaQuery.laptopXL`
					min-height: 32.8px;
				`}
				${mediaQuery.tabletL`
					min-height: 30.4px;
					a{
						min-height: 30.4px;
					}
				`}
				@media (max-width: 850px ) and (min-width: 768px) {
					margin: 0 4px;
				}
			}
			.hr {
				padding: 10px 11px;
				hr {
					margin: 0;
					margin-top: 3px;
					border: 0;
					border-top: 1px solid ${setColors.subtleGray};
				}
				${mediaQuery.laptopXL`
					padding-bottom: 9px;
				`}
				${mediaQuery.tabletL`
					padding-top: 12px;
					padding-bottom: 8px;
				`}
				@media (max-width: 850px) and (min-width: 768px) {
					margin: 0 4px;
				}
			}
		}
	}

	// Sub-menu styles when shown
	.menu > li:hover > .sub-menu {
		//clip: auto;
		opacity: 1;
		visibility: visible;
		//display: block !important;
		//transform: translateY(0) scaleY(1);
	}

	ul:nth-of-type(1) {
		li:first-of-type {
			&:hover {
				background-color: white;
				color: ${setColors.lightBlack};
			}
		}
	}

	p {
		box-sizing: border-box;
		font-size: ${setFontSize.mediumTiny};
		svg {
			width: 14px;
			margin: 0;
			margin-left: 4px;
			margin-bottom: 2px;
		}
	}

	li {
		transition: background-color 0.2s;
		&:hover {
			background-color: ${setColors.lightBlue};
			color: ${setColors.themeBlue};
			cursor: pointer;
			display: block;
		}
	}
	${mediaQuery.laptopXL`
		margin: 0 12px;
		p{
			font-size: ${setFontSize.veryTiny};
			svg{
				width: 12px;
			}
		}
	`}
	@media only screen and (min-width: 1001px) and (max-width: 1080px) {
		p {
			font-size: ${setFontSize.veryVeryTiny};
		}
	}
	${mediaQuery.tabletL`
		margin: 0 5px;
		p{
			font-size: ${setFontSize.tiniest};
		}
	`}
	@media (max-width: 850px) {
		margin: 0 4px;
		p {
			svg {
				margin-left: 1px;
				width: 10px;
			}
		}
	}
`;

export const StyledSubMenu = styled.div`
	opacity: 0;
	transition: all 0.3s;
	transform-origin: top;
	background-color: ${setColors.white};
	box-shadow: 0px 4px 4px rgb(0 0 0 / 10%), 0px -4px 4px rgb(0 0 0 / 10%);
	list-style: none;
	position: fixed;
	top: ${({ headerHeight }) => headerHeight};
	left: 0;
	width: 100%;
	visibility: hidden;
	transform-origin: center -20px;
	transform: rotateX(-15deg);
	transition: transform 250ms ease 0s, opacity 250ms ease 0s;
`;

export const LeftSectionWrapper = styled.div`
	padding: 0 20px 0 20px;
	${mediaQuery.mobileXL`
		padding: 0 5px`}
`;

export const RightSectionWrapper = styled.div`
	background: ${setColors.lightestGray};
	padding: 0 20px 20px 20px;
	${mediaQuery.mobileXL`
		padding: 0 25px 25px 25px;`}
`;

export const StyledDropdownSectionHeader = styled.div`
	flex-grow: 0;
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.tiniest};
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 15px;
	letter-spacing: normal;
	text-align: left;
	color: ${({ isLeftTitle }) =>
		isLeftTitle ? setColors.lightBlack : setColors.black};
	padding: 30px 0 17px 0;
	border-bottom: 1px solid ${setColors.warmGray};
	${mediaQuery.mobileXL`
		padding: 20px 0;
    margin: 0 20px;`}
`;

export const StyledLabel = styled.div`
	flex-grow: 0;
	font-family: ${props =>
		!props.isLight ? setFontFamily.bold : setFontFamily.book};
	font-size: ${setFontSize.mediumTiny};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: ${setColors.lightBlack};
	line-height: 20px;

	${mediaQuery.laptopML`
		font-size: ${setFontSize.veryVeryTiny};
		line-height: normal
	`}
	${mediaQuery.tabletXL`
		font-size: ${setFontSize.minute}`}
	${mediaQuery.tabletM`
		font-size: ${setFontSize.veryTiny}`}
	${mediaQuery.mobileXL`
		font-size: ${setFontSize.mediumTiny}`}
`;

export const StyledSubText = styled.div`
	flex-grow: 0;
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.tiniest};
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	font-weight: 450;
	line-height: 15px;
	color: ${setColors.planCardGrey};
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;

	${mediaQuery.laptopML`
		font-size: ${setFontSize.minute};
		line-height: normal
	`}
	${mediaQuery.tabletXL`
		font-size: ${setFontSize.smallest};`}
	${mediaQuery.tabletM`
		font-size: ${setFontSize.tiniest}`}
	${mediaQuery.mobileXL`
		line-height: 15px;`}
`;

export const NavLink = styled(Link)`
	// define this after StyledSubText & StyledLabel
	color: ${setColors.lightBlack};
	text-decoration: none;
	display: block;
	width: ${props => props.width || '100%'};
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'unset')};
	&:hover {
		background-color: ${setColors.lightBlue};
		color: ${setColors.themeBlue};
		cursor: pointer;
	}
	&:hover ${StyledLabel} {
		color: ${setColors.themeBlue};
	}
	&:hover ${StyledSubText} {
		color: ${setColors.lightBlack};
	}
`;

export const StyledTextWrapper = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
	padding: 0;
	${mediaQuery.mobileXL`
		gap: 10px;`}
`;

export const StyledSubDropdown = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-bottom: 40px;
	max-width: 1250px;
	margin: 0 auto 35px auto;
	column-gap: 30px;
	padding: 0 10px;
	${mediaQuery.desktopXS`			//1500
		max-width: 1240px;
	`};
	${mediaQuery.laptopL`				//1300
		max-width: 1000px;
		grid-template-columns: 2fr 2fr 1fr 1fr;
		column-gap: 10px;
	`}
	${mediaQuery.laptopXS`				//1050
		max-width: 770px;
		column-gap: 5px;		
	`}
`;

export const DropdownSectionWrapper = styled.div`
	a {
		border-radius: 7px;
	}
	margin: 10px 0;
`;

export const StyledSubContent = styled.div`
	margin: 10px 0;
	${mediaQuery.mobileXL`
	margin: 10px 20px;`}
`;

export const GatsbyLink = styled(Link)`
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.mediumTiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	margin: 0 20px;
	display: flex;
	align-items: center;
	color: ${setColors.lightBlack};
	text-decoration: none;

	${mediaQuery.laptopXL`
		margin: 0 12px;
		font-size: ${setFontSize.veryTiny};
	`}
	@media only screen and (min-width: 1001px) and (max-width: 1080px) {
		font-size: ${setFontSize.veryVeryTiny};
	}
	${mediaQuery.tabletL`
		margin: 0 5px;
		font-size: ${setFontSize.tiniest};
	`}
	@media (max-width: 850px) {
		margin: 0 4px;
	}
`;

// mobile nav styles

export const MobileNavigation = styled.div`
	display: none;

	${mediaQuery.mobileXL`
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.burgerIcon{
			width: 21px;
			height: 21px;
			cursor: pointer;
			position: absolute;
			right: 15px;
		}
	`}
`;

export const Bar = styled.div`
	padding: 20px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 1;
	.logo {
		width: 130px;
	}
	span {
		svg {
			width: 28px;
			height: 28px;
			transform: translateX(30%);
			cursor: pointer;
		}
	}
`;

export const NavContent = styled.div`
	min-height: calc(100vh - 240px);
	height: fit-content;
	p {
		margin: 0%;
	}

	li {
		list-style: none;
		font-size: ${setFontSize.normalSmall};
		padding: 1rem 0rem;
	}
	span {
		font-family: ${setFontFamily.bold};
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: ${setFontSize.verySmall};
		font-weight: ${setFontWeight.normal};
		margin: 5px 25px;
		line-height: 3;
		letter-spacing: 1.6px;
		cursor: pointer;
		border-bottom: 1px solid ${setColors.warmGray};
		svg {
			transform: translateX(6px);
		}
		a {
			color: ${setColors.lightBlack};
			text-decoration: none;
		}
		${mediaQuery.mobileS`
			line-height: 2.2;
		`}
	}

	ul {
		margin: 0;
		margin-left: 15px;
		padding: 8px;
		width: 220px;
		li {
			padding: 0;
		}
		a {
			line-height: 3;
			padding: 5px 25px;
			&:hover {
				background-color: transparent;
			}
		}
		p {
			line-height: 3;
			padding: 5px 25px;
			font-family: ${setFontFamily.bold};
			&:hover {
				color: ${setColors.themeBlue};
				cursor: default;
			}
		}
		.hr {
			padding: 5px 25px;
			hr {
				border: 0;
				border-top: 1px solid ${setColors.subtleGray};
				margin: 0;
				margin-top: 3px;
			}
		}
		${mediaQuery.mobileS`
			a{
				line-height: 2.2;
			}
			p{
				line-height: 2.2;
			}
		`}
	}
`;

export const BottomButtons = styled.div`
	position: sticky;
	width: 100%;
	bottom: 0;
`;

export const MobileCTAButton = styled(Link)`
	text-decoration: none;
	font-size: ${setFontSize.verySmall};
	background-color: ${props => (props.blue ? 'transparent' : 'white')};
	background-image: ${props =>
		props.blue &&
		`linear-gradient(to bottom, ${setColors.darkBlueGradiant}, ${setColors.lightBlueGradiant})`};
	color: ${props => (props.blue ? 'white' : `${setColors.themeBlue}`)};
	display: flex;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
`;

export const FullDropdown = styled.div`
	margin: 0 20px;

	// Menu styles
	ul:nth-of-type(1) {
		background-color: lighten(gray, 15%);
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;

		// Menu link styles

		// Top-level menu items
		> li {
			display: block;
			position: relative;
			p {
				line-height: 66px;
			}
		}
		li:first-of-type {
			&:hover {
				background-color: ${setColors.white};
				color: ${setColors.lightBlack};
			}
		}
	}

	p {
		box-sizing: border-box;
		font-size: ${setFontSize.mediumTiny};
		svg {
			width: 14px;
			margin: 0;
			margin-left: 4px;
			margin-bottom: 2px;
		}
	}

	li {
		transition: background-color 0.2s;
		&:hover {
			background-color: ${setColors.lightBlue};
			color: ${setColors.themeBlue};
			cursor: pointer;
			display: block;
		}
	}
	${mediaQuery.laptopXL`
		margin: 0 12px;
		p{
			font-size: ${setFontSize.veryTiny};
			svg{
				width: 12px;
			}
		}
	`}
	@media only screen and (min-width: 1001px) and (max-width: 1080px) {
		p {
			font-size: ${setFontSize.veryVeryTiny};
		}
	}
	${mediaQuery.tabletL`
		margin: 0 5px;
		p{
			font-size: ${setFontSize.tiniest};
		}
	`}
	@media (max-width: 850px) {
		margin: 0 4px;
		p {
			svg {
				margin-left: 1px;
				width: 10px;
			}
		}
	}
	${mediaQuery.mobileXL`
		margin: 0px`}

	// full width dropdown
	.items {
		line-height: normal;
		display: flex;
		max-width: 1440px;
		margin: 0 auto;
		${mediaQuery.desktopXS`			//1500
		max-width: 1240px;
	`};
		${mediaQuery.laptopML`				//1250
		max-width: 1040px;
	`}
	}

	.child-items {
		width: 267px;
		flex-grow: 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px 10px;
		gap: 16px;
		img {
			height: 24px;
			width: 24px;
		}
		border-radius: 7px;
		${mediaQuery.mobileXL`				//767
		width: calc(100% - 20px);
		padding: 20px 10px
`}
	}

	#auto-shrink {
		@media (max-width: 1300px) and (min-width: 1050px) {
			width: 250px;
		}

		@media (max-width: 1050px) and (min-width: 767px) {
			width: 190px;
		}
	}

	.child-link-items {
		width: 267px;
		padding: 10px;
		border-radius: 7px;

		@media (max-width: 1300px) and (min-width: 1050px) {
			width: 190px;
		}
		@media (max-width: 1050px) and (min-width: 767px) {
			width: 135px;
		}
		${mediaQuery.mobileXL`				//767
		width: calc(100% - 20px);
`}
	}

	.menu > li:hover > .full-width-menu {
		opacity: 1;
		visibility: visible;
		transform: rotateX(0deg);
	}
`;

export const StyledBackground = styled.div`
	background: ${setColors.lightestGray};
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50%;
	z-index: -1;
`;

export const LeftResourceWrapper = styled.div`
	margin: 10px 0 20px 0;
	a {
		border-radius: 7px;
	}
	${mediaQuery.laptopML`
		margin: 10px 0 20px 0`}
	${mediaQuery.tabletM`
		margin: 10px 0 20px 0;`}
	${mediaQuery.mobileXL`
		margin: 10px 20px;`}	    
}`;

export const FeaturedResourceWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 16px;
	column-gap: 21px;
	a {
		background: ${setColors.white};
		padding: 0;
		border-radius: 10px;
	}
	margin: 10px 0 20px 0;
	${mediaQuery.laptopML`
		margin: 10px 0 20px 0`}
	${mediaQuery.tabletXL`
		column-gap: 14px`}
	${mediaQuery.tabletM`
		grid-template-columns: 1fr;
		margin: 10px 0 20px 0;`}
		${mediaQuery.mobileXL`
		margin: 20px 0 20px 0;
		row-gap: 20px;`}
`;

export const BlogsContainer = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 16px;
	height: 130px;
	${mediaQuery.laptopML`
		height: 100px;
		padding: 14px;`}
	${mediaQuery.tabletXL`
		height: 80px;
		padding: 10px;`}
		${mediaQuery.mobileXL`
		height: 100px;
		padding: 16px;`}
`;

export const BlogImgWrapper = styled.div`
	height: 100%;
	width: 130px;
	.gatsby-image-wrapper {
		height: 100%;
		width: 130px;
	}
	${mediaQuery.laptopML`
		height: 100px;
		width: 100px;
		.gatsby-image-wrapper {
			width: 100px;
		}
	`}
	${mediaQuery.tabletXL`
		height: 80px;
		width: 80px;
		.gatsby-image-wrapper {
			width: 80px;
		}`}
`;

export const BlogInfo = styled.div`
	margin-left: 16px;
	position: relative;
	height: 100%;
	div {
		font-family: ${setFontFamily.bold};
		font-style: normal;
	}
	div:nth-child(1) {
		font-size: ${setFontSize.tiniest};
		line-height: 15px;
		color: ${setColors.planCardGrey};
		margin: 8px 0px;
		${mediaQuery.laptopML`
		font-size: ${setFontSize.minute};
		line-height: normal
	`}
		${mediaQuery.tabletXL`
		margin: 4px 0px;
		font-size: ${setFontSize.smallest};`}
		${mediaQuery.mobileXL`
		font-size: ${setFontSize.tiniest};
		line-height: 15px;`}
	}

	div:nth-child(2) {
		font-size: ${setFontSize.mediumTiny};
		line-height: 20px;
		color: ${setColors.lightBlack};
		margin: 8px 0px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
		${mediaQuery.laptopML`
		font-size: ${setFontSize.veryVeryTiny};
		line-height: normal
	`}
		${mediaQuery.tabletXL`
		margin: 4px 0px;
		font-size: ${setFontSize.minute};`}
		${mediaQuery.tabletM`
		font-size: ${setFontSize.veryVeryTiny}`}
		${mediaQuery.mobileXL`
		font-size: ${setFontSize.mediumTiny};
		line-height: 20px;`}
	}

	div:nth-child(3) {
		font-size: ${setFontSize.tiniest};
		line-height: 15px;
		color: ${setColors.themeBlue};
		position: absolute;
		bottom: 0;
		${mediaQuery.laptopML`
		font-size: ${setFontSize.minute};
	`}
		${mediaQuery.tabletXL`
		font-size: ${setFontSize.smallest};`}
		${mediaQuery.mobileXL`
		font-size: ${setFontSize.tiniest};
		line-height: 15px;`}
	}
`;

//features dropdown content

export const FeaturesContentWrapper = styled.div`
	width: 70%;
	padding: 0 20px 20px 20px;
	margin: 0 auto;
	${mediaQuery.tabletM`
		width: 80%`}
	${mediaQuery.mobileXL`
	width: 100%;
	padding: 0 5px;`}
`;

export const FeaturesWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 5px;
	margin: 10px 0;
	a {
		border-radius: 7px;
	}
	${mediaQuery.tabletM`
		grid-template-columns: 1fr 1fr;
	`}
	${mediaQuery.mobileXL`
	grid-template-columns: 1fr;
	margin: 10px 20px;`}
`;
