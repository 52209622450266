import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { createGlobalStyle } from 'styled-components';
import ThemeProvider from '../theme-provider/themeProvider';
import * as S from './styles';
import '../../../fonts/typography.css';
import { setColors } from '../../../../utils/helpers';
import theme from '../../../../utils/theme';

const Layout = ({ children }) => {
	const [top, setTop] = useState(true);
	const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY === 0) {
				setTop(true);
			} else {
				setTop(false);
			}
		});
		return () => {
			window.removeEventListener('scroll', () => {});
		};
	}, []);

	const GlobalStyle = createGlobalStyle`
		*{
			outline: none;
		}
		body {
			margin: 0;
			padding: 0;
			font-family: 'CircularStd-Book', sans-serif;
			color: ${setColors.lightBlack};
			-webkit-tap-highlight-color: transparent;
			overflow: ${mobileMenuIsOpen ? 'hidden' : 'auto'};
			overflow-x: hidden;
			
		}
		.ReactModal__Body--open{
			overflow: hidden;
		}
	`;
	return (
		<ThemeProvider theme={theme}>
			<Helmet
				htmlAttributes={{
					lang: 'en',
				}}
			>
				{process.env.GATSBY_ISPRODUCTION.toString().trim().toLowerCase() ===
				'true' ? (
					<script src="https://www.googleoptimize.com/optimize.js?id=OPT-WSTMM43"></script>
				) : (
					<script src="https://www.googleoptimize.com/optimize.js?id=OPT-58NSHVR"></script>
				)}
				{process.env.GATSBY_ISPRODUCTION.toString().trim().toLowerCase() ===
					'false' && <meta name="robots" content="noindex" />}
			</Helmet>
			<GlobalStyle />
			<Navbar
				top={top}
				mobileMenuIsOpen={mobileMenuIsOpen}
				setMobileMenuIsOpen={setMobileMenuIsOpen}
			/>
			<S.Main> {children}</S.Main>
			<Footer />
		</ThemeProvider>
	);
};

export default Layout;
